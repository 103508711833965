import { IonButton, IonContent,  IonPage, IonIcon,IonCardContent,IonText} from '@ionic/react';
import React, { useEffect, useState} from 'react';
import { useHistory } from "react-router-dom";

import MlmHeader from '../components/MlmHeader';
import { detect } from 'detect-browser';
import { getSharedTo,sharedTo,checkToken,deleteShare } from '../services/api';
import Loader from '../components/Loader';



interface Contact{
  id?: string, 
  name?: string, 
  val?: string, 
  type?: string, 

}


const HomeScreen: React.FC = () => {

    const browser = detect();
// handle the case where we don't detect the browser

  const [bmessage, setMessage] = useState<string>('');

 // handle the case where we don't detect the browser


useEffect(() => {
  if (browser) {
    console.log(browser.name);
    console.log(browser.version);
    console.log(browser.os);
  }
  switch (browser && browser.name ) {
    case 'chrome':
      console.log('first')
      if(browser && browser.os !== 'Android OS'){
        setMessage('chromeDesktop');
      }else{
        setMessage('chromeAndroid');
      }
      break;
      case 'safari':
        if(browser && browser.os === 'iOS'){
          setMessage('iPhone');
        }else{
          setMessage('notSupported');
        }
        break;
      case 'ios':
        setMessage('iPhone');
        break;
    
  
    default:
      setMessage('notSupported');
  }
}, [])
  return (
    <IonPage>
       <MlmHeader themecolor='black' position='relative'/>

      <IonContent fullscreen className='main_content share_screen ion-padding'>
        <div className="container flex-container">

        {(browser) &&(
         
         <p> {browser.type} <br/>{browser.name}<br/>{browser.version}<br/>{browser.os}</p>
        )}
        {((bmessage === 'notSupported') &&(
          <h2> Oops! It looks like your browser doesn't support this function. No worries, though! Give it a shot with a different browser, and you should be good to go.
          </h2>
        ))}

 {((bmessage === 'chromeDesktop') &&(
                  <>
                  <h1> Install "Matan's life Map" on Google Chrome</h1>
                 <p><strong>Step 1 –</strong>  click Tap the three-dot menu icon in the top right or the icon on the adress bar.</p>
                 <img className="attachment-large size-medium " src="./assets/img/chrome_1.jpg" alt="screeshot #1" width="500" height="83"/>
                 <p><strong>Step 2 –</strong>  Select “Install Matan's Life Map” from the menu.</p>
                 <img className="attachment-large size-medium " src="./assets/img/chrome_2.jpg" alt="screeshot #2" width="500" height="201"/>
                
                 
                 <p><strong>Step 3 –</strong>  A pop-up from your home screen launcher will appear, and you can add it by clicking “Install.”</p>
                 <img className="attachment-large size-medium " src="./assets/img/chrome_3.jpg" alt="screeshot #3" width="500" height="405"/>
                 </>
            ))}
            {((bmessage === 'chromeAndroid') &&(
                <>
                 <h1> Add to the Home Screen on Google Chrome</h1>
                <p><strong>Step 1 –</strong>  Tap the three-dot menu icon in the top right.</p>
                <img className="attachment-large size-medium " src="./assets/img/chrome_1a.jpg" alt="screeshot #1" width="500" height="83"/>
                <p><strong>Step 2 –</strong>  Select “Add to Home Screen” from the menu.</p>
                <img className="attachment-large size-medium " src="./assets/img/chrome_2a.jpg" alt="screeshot #2" width="500" height="201"/>
             
                
                <p><strong>Step 3 –</strong>  A pop-up from your home screen launcher will appear, and then tap “Add”</p>
                <img className="attachment-large size-medium " src="./assets/img/chrome_3a.jpg" alt="screeshot #3" width="500" height="405"/>
                </>
            ))}
            {((bmessage === 'iPhone') &&(
                <>
                <h1> Add to the Home Screen on iPhone or iPad</h1>
                <p><strong>Step 1 –</strong>  Click on the <span><b>Share </b></span>button </p>
						    <img className="attachment-large size-medium " src="./assets/img/sc_1-2.jpg" alt="screeshot #1" width="475" height="854"/>

                <p><strong>Step 2 –</strong> Click on the “<strong>Add to Home Screen</strong>” icon </p>
						    <img className="attachment-large size-medium " src="./assets/img/sc_2-2.jpg" alt="screeshot #2" width="475" height="854"/>

               <p><strong>Step 3 –</strong> Click on the <strong>Add</strong>&nbsp;in the upper-right corner.</p>
						    <img className="attachment-large size-medium " src="./assets/img/sc_3.jpg" alt="screeshot #3" width="475" height="854"/>

               <p>Click add “<strong>Add to Homescreen</strong>” in Safari in iPhone iOS </p>

                </>
            ))}
        
           
       
        </div>
        
      </IonContent>
      

    </IonPage>

  );
};

export default HomeScreen;
